var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"TopTradedShares"}},[_c('div',{staticClass:"headerpart"},[_c('img',{staticClass:"pcImg",attrs:{"src":require("../../../../../public/image/TopTradedBAPC.png"),"alt":""}}),_vm._v(" "),_c('img',{staticClass:"isoImg",attrs:{"src":require("../../../../../public/image/TopTradedBAIOS.png"),"alt":""}}),_vm._v(" "),_c('div',{staticClass:"scope"},[_c('div',{staticClass:"contentBox"},[_c('h1',{staticClass:"content_title",domProps:{"innerHTML":_vm._s(_vm.$t('nav.TTS'))}}),_vm._v(" "),_c('p',{staticClass:"content_hint",domProps:{"innerHTML":_vm._s(_vm.$t('TTSs.TrIiw'))}})])])]),_vm._v(" "),_c('div',{staticClass:"mainContent mainContent_no"},[_c('div',{staticClass:"scope"},[_c('div',{staticClass:"tableBox row"},[_c('div',{staticStyle:{"flex":"1"}},[_c('table',{staticClass:"table"},[_c('thead',[_c('tr',[_c('th',{staticClass:"warrantbg",staticStyle:{"padding":"10px 0px"},attrs:{"colspan":"4"}},[_vm._v("\n                  "+_vm._s(_vm.$t("TTSs.US"))+"\n                ")])]),_vm._v(" "),_c('tr',_vm._l((_vm.tableHeadContent),function(hi,hindex){return (hindex <= 3)?_c('th',{key:hindex,staticClass:"warrantbg",domProps:{"innerHTML":_vm._s(hi)}}):_vm._e()}),0)]),_vm._v(" "),_c('tbody',_vm._l((_vm.tableContentData),function(ci,cindex){return _c('tr',{key:cindex,class:_vm.$$.isMAYBANK(ci.issuer_name) ? 'overstriking' : ''},[_c('td',{on:{"click":function($event){return _vm.$$.toRelevantPage(false, ci.underlying_ric)}}},[_vm._v("\n                  "+_vm._s(ci.underlying_ticker)+"\n                ")]),_vm._v(" "),_c('td',[_vm._v("\n                  "+_vm._s(_vm.$$.formatNumber(_vm.$$.clearRM(ci.underlying_acvol_1), 0, 1))+"\n                ")]),_vm._v(" "),_c('td',[(ci.underlying_ticker.indexOf('FBMKLCI'))?_c('span',[_vm._v(" "+_vm._s(ci.underlying_curr == 'IDR' ? 'Rp'
                      : ci.underlying_curr))]):_vm._e(),_vm._v("\n                  "+_vm._s(_vm.$$.formatNumber(ci.underlying_price, 0, 1))+"\n                ")]),_vm._v(" "),_c('td',{class:ci.underlying_pctchng >= 0 ? 'green' : 'red'},[_vm._v("\n                  "+_vm._s(ci.underlying_pctchng >= 0
                      ? _vm.$$.changeRate(
                        _vm.$$.formatNumber(ci.underlying_pctchng, 1, 1)
                      )
                      : _vm.$$.formatNumber(ci.underlying_pctchng, 1, 1))+"\n                ")])])}),0)])]),_vm._v(" "),_c('div',{staticStyle:{"flex":"1"}},[_c('table',{staticClass:"table"},[_c('thead',[_c('tr',[_c('th',{staticClass:"marketbg",staticStyle:{"padding":"10px 0px"},attrs:{"colspan":"7"}},[_vm._v("\n                  "+_vm._s(_vm.$t("TTSs.MIW"))+"\n                ")])]),_vm._v(" "),_c('tr',_vm._l((_vm.tableHeadContent),function(hi,hindex){return (hindex > 3)?_c('th',{key:hindex,staticClass:"marketbg",domProps:{"innerHTML":_vm._s(hi)}}):_vm._e()}),0)]),_vm._v(" "),_c('tbody',_vm._l((_vm.tableContentData),function(ci,cindex){return _c('tr',{key:cindex,class:_vm.$$.isMAYBANK(ci.issuer_name) ? 'overstriking' : ''},[_c('td',{staticClass:"clickable",on:{"click":function($event){return _vm.$$.toRelevantPage(true, ci.dsply_nmll)}}},[_vm._v("\n                  "+_vm._s(ci.dsply_nmll)+"\n                ")]),_vm._v(" "),_c('td',[_vm._v("\n                  "+_vm._s(_vm.$$.spaceData(_vm.$$.formatNumber(ci.BID, 0, 1)))+"\n                ")]),_vm._v(" "),_c('td',{class:ci.BID_PCTCHNG >= 0 ? 'green' : 'red'},[_vm._v("\n                  "+_vm._s(ci.BID_PCTCHNG >= 0
                      ? _vm.$$.changeRate(_vm.$$.formatNumber(ci.BID_PCTCHNG, 1, 1))
                      : _vm.$$.formatNumber(ci.BID_PCTCHNG, 1, 1))+"\n                ")]),_vm._v(" "),_c('td',[_vm._v("\n                  "+_vm._s(_vm.$$.spaceData(ci.BIDSIZE) == "N/A"
                      ? _vm.$$.spaceData(ci.BIDSIZE)
                      : _vm.$$.formatNumber(_vm.$$.spaceData(ci.BIDSIZE) / 1000, 0, 1))+"\n                ")]),_vm._v(" "),_c('td',[_vm._v("\n                  "+_vm._s(_vm.$$.spaceData(ci.ASKSIZE) == "N/A"
                      ? _vm.$$.spaceData(ci.ASKSIZE)
                      : _vm.$$.formatNumber(_vm.$$.spaceData(ci.ASKSIZE) / 1000, 0, 1))+"\n                ")]),_vm._v(" "),_c('td',[(ci.dsply_nmll.indexOf('FBMKLCI-'))?_c('span',[_vm._v("\n                    "+_vm._s(ci.underlying_curr == 'IDR' ? 'Rp' : ci.underlying_curr))]):_vm._e(),_vm._v("\n                  "+_vm._s(_vm.$$.formatNumber(ci.exercise_price, 0, 1))+"\n                ")]),_vm._v(" "),_c('td',[_vm._v("\n                  "+_vm._s(ci.conv_ratio_cal)+" : 1\n                ")])])}),0)])])])]),_vm._v(" "),_c('img',{staticClass:"shiftLeft shift",attrs:{"src":require("../../../../../public/image/shiftRight.png"),"alt":""},on:{"click":_vm.clickShiftLeft}}),_vm._v(" "),_c('img',{staticClass:"shiftRight shift",attrs:{"src":require("../../../../../public/image/shiftRight.png"),"alt":""},on:{"click":_vm.clickShiftRight}})]),_vm._v(" "),_c('div',{staticClass:"bottom-disclaimer"},[_c('div',{staticClass:"scope"},[_vm._v(_vm._s(_vm.$t("Ttt.Amoibt"))+".")])])])}
var staticRenderFns = []

export { render, staticRenderFns }